import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const BreadcrumbContainer = ({ size, children }) => {
    return (
        <div className="c-breadcrumb">
            <div className="container-lg">
                <div className="row">
                    <div
                        className={`${
                            size === 'narrow'
                                ? 'col-lg-10 offset-lg-1'
                                : size === 'narrower'
                                ? 'col-lg-8 offset-lg-2'
                                : 'col-12'
                        } c-breadcrumb__inner`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

BreadcrumbContainer.propTypes = {
    size: PropTypes.oneOf(['standard', 'narrow', 'narrower']),
    children: PropTypes.node.isRequired
}

BreadcrumbContainer.defaultProps = {
    size: 'standard',
    children: <div>Elements go here</div>
}

export default BreadcrumbContainer
